import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import {
  addEmailsToAdmins,
  addSubscriptionTypeToAdmins,
  getAdminsDetailsForAnalytics,
  getNews,
  getTeacherDetailsFromAdminList,
  uploadContentToDatabase,
  uploadFileToStorage,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";
import NewsBox from "../components/NewsBox";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import WorksheetBox from "../components/WorksheetBox";
import { useNavigate } from "react-router";
import CustomCard from "../components/generic/CustomCard";
import {
  downloadAnalyticalDataAsCSV,
  getAnalyticalData,
} from "../functions/analyticsFunctions";

const AnalyticsDashboard = () => {
  const navigate = useNavigate();
  const [allSchools, setAllSchools] = useState([]);
  const [trialSchools, setTrialSchools] = useState([]);
  const [payingSchools, setPayingSchools] = useState([]);
  const [analyticsData, setAnalyticData] = useState([]);

  const [allTeachers, setAllTeachers] = useState([]);
  const [trialTeachers, setTrialTeachers] = useState([]);
  const [payingTeachers, setPayingTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data2 } = useContext(UserContext);

  useEffect(() => {
    document.title = "Daly Exercise+ | Glen Admin";
    if (
      data2.Guid !== "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" &&
      data2.Guid !== "bUNhMhxJiPS6Fz74LIbkYpomVK32"
    ) {
      navigate("/");
    }
    getAnalyticsDetails(data2);

    // addSubscriptionTypeToAdmins()
  }, [data2]);

  function toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t.toLocaleDateString();
  }

  const getAnalyticsDetails = async (data2) => {
    if (
      data2.Guid !== "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" &&
      data2.Guid !== "bUNhMhxJiPS6Fz74LIbkYpomVK32"
    ) {
      navigate("/");
    }
    setLoading(true);
    let allAnalyticalData = await getAnalyticalData();
    const date = 0;
    setAnalyticData(allAnalyticalData);
    getAllSchools(allAnalyticalData, date);
    getPayingSchools(allAnalyticalData, date);
    getTrialSchools(allAnalyticalData, date);
    getAllTeachers(allAnalyticalData, date);
    getPayingTeachers(allAnalyticalData, date);
    getTrialTeachers(allAnalyticalData, date);
    setLoading(false);
  };

  const getAllSchools = (data, timeframe = 0) => {
    let schools = {};
    data.forEach((d) => {
      if (
        d.IsAdmin &&
        !(d.SchoolID in schools) &&
        d.SchoolCreationDate._seconds * 1000 > timeframe
      ) {
        schools[d.SchoolID] = d;
      }
    });
    setAllSchools(Object.values(schools));
  };

  const getPayingSchools = (data, timeframe = 0) => {
    let schools = {};
    data.forEach((d) => {
      if (
        d.IsAdmin &&
        !(d.SchoolID in schools) &&
        d.SubscriptionType === "active" &&
        ((d.SubStart !== null && d.SubStart._seconds * 1000 > timeframe) ||
          (d.InvoiceDate !== null &&
            new Date(d.InvoiceDate).getTime() > timeframe))
      ) {
        schools[d.SchoolID] = d;
      }
    });
    setPayingSchools(Object.values(schools));
  };

  const getTrialSchools = (data, timeframe = 0) => {
    let schools = {};
    data.forEach((d) => {
      if (
        d.IsAdmin &&
        !(d.SchoolID in schools) &&
        d.SubscriptionType === "trialing" &&
        ((d.SubStart !== null && d.SubStart._seconds * 1000 > timeframe) ||
          (d.InvoiceDate !== null &&
            new Date(d.InvoiceDate).getTime() > timeframe))
      ) {
        schools[d.SchoolID] = d;
      }
    });
    setTrialSchools(Object.values(schools));
  };

  const getAllTeachers = (data, timeframe = 0) => {
    let teachers = [];
    data.forEach((d) => {
      if (d.SchoolCreationDate._seconds * 1000 > timeframe) {
        teachers.push(d);
      }
    });
    setAllTeachers(teachers);
  };

  const getPayingTeachers = (data, timeframe = 0) => {
    let teachers = [];
    data.forEach((d) => {
      if (
        d.SubscriptionType === "active" &&
        ((d.SubStart !== null && d.SubStart._seconds * 1000 > timeframe) ||
          (d.InvoiceDate !== null &&
            new Date(d.InvoiceDate).getTime() > timeframe))
      ) {
        teachers.push(d);
      }
    });
    setPayingTeachers(teachers);
  };

  const getTrialTeachers = (data, timeframe = 0) => {
    let teachers = [];
    data.forEach((d) => {
      if (
        d.SubscriptionType === "trialing" &&
        ((d.SubStart !== null && d.SubStart._seconds * 1000 > timeframe) ||
          (d.InvoiceDate !== null &&
            new Date(d.InvoiceDate).getTime() > timeframe))
      ) {
        teachers.push(d);
      }
    });
    setTrialTeachers(teachers);
  };

  const changeAnalyticsTimeframe = (e) => {
    const val = e.target.value;
    let date = new Date().getTime();
    if (val === "day") {
      date -= 1 * 24 * 60 * 60 * 1000;
    } else if (val === "week") {
      date -= 7 * 24 * 60 * 60 * 1000;
    } else if (val === "month") {
      date -= 31 * 24 * 60 * 60 * 1000;
    } else if (val === "year") {
      date -= 365 * 24 * 60 * 60 * 1000;
    } else if (val === "all") {
      date = 0;
    }

    getAllSchools(analyticsData, date);
    getPayingSchools(analyticsData, date);
    getTrialSchools(analyticsData, date);
    getAllTeachers(analyticsData, date);
    getPayingTeachers(analyticsData, date);
    getTrialTeachers(analyticsData, date);
  };

  const downloadDataAsCSV = (data, title) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "SchoolName,SchoolRoll,SchoolCreationDate,AdminEmail,AdminFirstName,AdminSurname,AdminContactNumber,SubscriptionStatus,SubscriptionCount,SubscriptionCreationData,SubscriptionPeriodStart,SubscriptionPeriodEnd,PayByInvoice\r\n";

    data.forEach((row) => {
      if (row !== null) {
        csvContent +=
          (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolRoll ? row.SchoolRoll.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolCreationDate
            ? toDateTime(row.SchoolCreationDate._seconds)
            : "") +
          "," +
          row.Email +
          "," +
          row.FirstName +
          "," +
          row.Surname +
          "," +
          row.ContactNumber +
          "," +
          (row.SubscriptionType ? row.SubscriptionType : "none") +
          "," +
          (row.SubCount ? row.SubCount : 0) +
          "," +
          (row.PayByInvoice
            ? new Date(row.InvoiceDate).toLocaleDateString()
            : row.SubStart
            ? toDateTime(row.SubStart._seconds)
            : "") +
          "," +
          (row.CurrentSubStart
            ? toDateTime(row.CurrentSubStart._seconds)
            : "") +
          "," +
          (row.CurrentSubEnd ? toDateTime(row.CurrentSubEnd._seconds) : "") +
          "," +
          (row.PayByInvoice ? row.PayByInvoice : false) +
          "\r\n";
      }
    });
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const getSubscriptionCountFromData = (data) => {
    let total = 0;
    console.log(data.length);
    data.forEach((d) => {
      console.log(d.SubCount);
      total += d.SubCount ? parseInt(d.SubCount) : 0;
    });

    console.log("Total: " + total);
    return total;
  };

  const downloadTeacherDataAsCSV = (data, title) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "SchoolName,SchoolRoll,SchoolCreationDate,Email,First Name,Surname,Contact Number,SubscriptionStatus,SubscriptionCount,SubscriptionCreationData,SubscriptionPeriodStart,SubscriptionPeriodEnd,PayByInvoice\r\n";

    data.forEach((row) => {
      if (row !== null) {
        csvContent +=
          row.SchoolID +
          "," +
          (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolRoll ? row.SchoolRoll.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolCreationDate
            ? toDateTime(row.SchoolCreationDate._seconds)
            : "") +
          "," +
          row.Email +
          "," +
          row.FirstName +
          "," +
          row.Surname +
          "," +
          row.ContactNumber +
          "," +
          (row.SubscriptionType ? row.SubscriptionType : "none") +
          "," +
          (row.SubCount ? row.SubCount : 0) +
          "," +
          (row.PayByInvoice
            ? new Date(row.InvoiceDate).toLocaleDateString()
            : row.SubStart
            ? toDateTime(row.SubStart._seconds)
            : "") +
          "," +
          (row.CurrentSubStart
            ? toDateTime(row.CurrentSubStart._seconds)
            : "") +
          "," +
          (row.CurrentSubEnd ? toDateTime(row.CurrentSubEnd._seconds) : "") +
          "," +
          (row.PayByInvoice ? row.PayByInvoice : false) +
          "\r\n";
      }
    });
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", title);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const getSubscriptioIntervalFromData = (data, interval) => {
    let res = [];
    data.forEach((d) => {
      if (interval === "monthly") {
        if (
          d.SubStart !== null &&
          d.CurrentSubEnd._seconds - d.CurrentSubStart._seconds < 3592000
        ) {
          res.push(d);
        }
      } else {
        if (
          d.SubStart === null ||
          d.CurrentSubEnd._seconds - d.CurrentSubStart._seconds > 3592000
        ) {
          res.push(d);
        }
      }
    });

    return res;
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Analytics</h1>
          <br />
          <Form.Group className="mb-3" controlId="userRole">
            <Form.Label className="form-label-text">Timeframe</Form.Label>
            <Form.Select onChange={(event) => changeAnalyticsTimeframe(event)}>
              <option value={"all"}>All Time</option>
              <option value={"day"}>Last Day</option>
              <option value={"week"}>Last Week</option>
              <option value={"month"}>Last Month</option>
              <option value={"year"}>Last Year</option>
            </Form.Select>
          </Form.Group>

          <hr />
          <h3 className="pageTitle title-margin">Schools</h3>
          <Row>
            <Col md={4}>
              <CustomCard
                style={1}
                loading={loading}
                title={"Total Schools"}
                text={allSchools.length}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadDataAsCSV(allSchools, "All_Schools.csv")
                }
              />
            </Col>

            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Paying Schools"}
                text={payingSchools.length}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadDataAsCSV(payingSchools, "Paying_Schools.csv")
                }
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={3}
                loading={loading}
                title={"Trial Schools"}
                text={trialSchools.length}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadDataAsCSV(trialSchools, "Trialing_Schools.csv")
                }
              />
            </Col>
          </Row>
          <hr />
          <h3 className="pageTitle title-margin">Teachers</h3>
          <Row>
            <Col md={4}>
              <CustomCard
                style={1}
                loading={loading}
                title={"Total Teachers"}
                text={allTeachers.length}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(allTeachers, "All_Teachers.csv")
                }
              />
            </Col>

            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Paying Teachers"}
                text={getSubscriptionCountFromData(payingSchools)}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(
                    payingTeachers,
                    "Paying_Teachers.csv"
                  )
                }
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={3}
                loading={loading}
                title={"Trial Teachers"}
                text={getSubscriptionCountFromData(trialSchools)}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(
                    trialTeachers,
                    "Trialing_Teachers.csv"
                  )
                }
              />
            </Col>
          </Row>

          <Row>
            {/* <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Schools Yearly"}
                text={
                  getSubscriptioIntervalFromData(payingSchools, "yearly").length
                }
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(allTeachers, "All_Teachers.csv")
                }
              />
            </Col> */}

            {/* <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Schools Monthly"}
                text={
                  getSubscriptioIntervalFromData(payingSchools, "monthly")
                    .length
                }
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(
                    payingTeachers,
                    "Paying_Teachers.csv"
                  )
                }
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Teachers Yearly"}
                text={getSubscriptionCountFromData(
                  getSubscriptioIntervalFromData(payingSchools, "yearly")
                )}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(
                    trialTeachers,
                    "Trialing_Teachers.csv"
                  )
                }
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"Teachers Monthly"}
                text={getSubscriptionCountFromData(
                  getSubscriptioIntervalFromData(payingSchools, "monthly")
                )}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(
                    trialTeachers,
                    "Trialing_Teachers.csv"
                  )
                }
              />
            </Col> */}
          </Row>
          <hr />
          <h3 className="pageTitle title-margin">Marketing</h3>
          <Stack>
            <button
              className={`custom-card-button custom-card-button-variant-1`}
              onClick={() => downloadAnalyticalDataAsCSV(analyticsData)}
            >
              Download Emails for Marketing
            </button>
          </Stack>
        </Container>
      </div>
    </Container>
  );
};

export default AnalyticsDashboard;
